import jquery from "jquery"

window.jQuery = jquery
window.$ = jquery

const hideAlert = function() {
    let alert = $('#alert');
    if (alert.length) {
        alert.hide(400);
    }
};

const setDeleteAlert = function() {
    $('td.actions a[data-turbo-method="delete"]').click(function(e){
        return confirm($(this).data('confirm'));
    });
}

window.addEventListener('turbo:render', () => {
    console.log('turbo:render');
    setTimeout(hideAlert, 3000);
    setDeleteAlert();
});

window.addEventListener('load', () => {
    console.log('load');
    setTimeout(hideAlert, 3000);
    setDeleteAlert();
});
